<template>
<div class="page">
    <v-list ref="list" :headers="headers" :tableUrl="tableUrl" :searchParam.sync="searchParam">
        <template #headBtnSlot>
            <!-- <v-button text="新增" @click="toAdd"></v-button> -->
        </template>
        <template #searchSlot>
            <v-input disabled label="话题标题:" v-model="topicTitle" />
            <v-input label="签收人：" placeholder='请输入签约人姓名' v-model="searchParam.userName" />
            <v-input label="手机号：" placeholder='请输入签约人手机号' v-model="searchParam.mobiles" />
            <community-select placeholder='请选择' v-model="searchParam.communityId" label="选择园区" />

        </template>
        <template #operateSlot="scope">
            <v-button text="取消" v-if="scope.row.canEdit == undefined || scope.row.canEdit" type="text" @click="cancel(scope.row.id)" />
        </template>
    </v-list>
</div>
</template>

<script>
import {
    getSigninUsersUrl,
    cancelSigninUrl,
    getTopicDetailURL
} from "./api.js";
import {
    sexStatus,
    sexStatusMap,
    sexStatusOps,
} from './map.js'
export default {
    name: 'signList',
    data() {
        return {
            topicTitle: '',
            searchParam: {
                id: this.$route.query.id,
                userName: '',
                mobiles: '',
                communityId: ''
            },
            tableUrl: getSigninUsersUrl,
            headers: [{
                    prop: "userName",
                    label: "签收人",
                },
                {
                    prop: "mobileNum",
                    label: "手机号",
                },
                {
                    prop: "sex",
                    label: "性别",
                    formatter: (row, prop) => {
                        return sexStatusMap[row[prop]] || "--";
                    },
                },
                {
                    prop: "houseArea",
                    label: "签约人房号",
                },
                {
                    prop: "createTime",
                    label: "签约时间",
                },
            ],
        }
    },
    created() {
        this.$setBreadList('签收列表');
        this.getDetail()
    },
    methods: {
        cancel(id) {
            this.$confirm("是否取消此签收人?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let params = {
                    id: id,
                };
                this.$axios
                    .post(`${cancelSigninUrl}`, this.$qs.stringify(params))
                    .then((res) => {
                        if (res.code === 200) {
                            this.$message.success("操作成功");
                            this.$refs.list.search();
                        } else {}
                    });
            });
        },
        getDetail() {
            let params = {
                id: this.$route.query.id
            }
            this.$axios.get(`${getTopicDetailURL}`, {
                params: params
            }).then(res => {
                if (res.code === 200) {
                    this.topicTitle = res.data.topicTitle
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.page {
    box-sizing: border-box;
    height: 100%;
}
</style>
